import './App.css';
// import PokeAPI from './PokeAPI'
import Poke from './Poke'


function App() {


  return (
    <div>
      {/* <PokeAPI /> */}
      <Poke />
    </div>
  );
}

export default App;
